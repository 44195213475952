import '../i18n';
import React from 'react';
import { useGallery } from '../hooks';
import { Page, Section, Gallery } from '../components';


export default function GalleryPage() {
  const pictures = useGallery(true);

  return (
    <Page>
      <Section wide pad="2em 0em">
        <Gallery
          compact
          items={pictures}
        />
      </Section>
    </Page>
  );
}

